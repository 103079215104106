<template>
  <div class="web_box">
    <van-nav-bar
      title="关于我们"
      left-arrow
      @click-left="toBack()"
      fixed
      class="header_class"
      :border="false"
    >
      <div class="back_icon" slot="left" />
    </van-nav-bar>

    <div class="content">
      <div v-html="info">
        {{ info }}
      </div>
    </div>
  </div>
</template>
<script>
import {getAboutusInfo} from "@/utils/api";

export default {
  data() {
    return {
      info: "",
    };
  },
  mounted() {
    getAboutusInfo()
      .then((res) => {
        this.info = res.data.aboutusInfo.content;
        console.log(this.info);
      })
      .catch(() => {});
  },
};
</script>
<style scoped>
.content >>> p,
.content >>> div {
  color: #8cbcd6 !important;
  font-size: 28px !important;
}
</style>
<style lang="scss" scoped>
.content {
  width: 90%;
  padding: 88px 30px 0;
  text-indent: 2em;
  text-align: justify;
}
</style>
